import request from '@/utils/request'

export function sendMail(mailData) {
    return request({
        url: '/inbox',
        method: 'post',
        data: mailData
    })
}

//{creator,title,content,user_filters,rewards,date_begin,date_end}
export function sendMailGroup(mailGroupData) {
    return request({
        url: '/mailgroup/add',
        method: 'post',
        data: mailGroupData
    })
}

export function getInbox(userID, start, limit) {
    return request({
        url: '/inbox/' + userID,// + '?start=' + start + '&limit=' + limit,
        method: 'get'
    })
}

export function getTrackingUsers() {
    return request({
        url: '/inbox/tracking_users/all',
        method: 'get'
    })
}

export function getTrackingUserMail(userID) {
    return request({
        url: '/inbox/tracking_users_mail/' + userID,
        method: 'get'
    })
}

export function clearTrackingUserMail(userID, mailID) {
    return request({
        url: '/inbox/delete_tracking_users_mail/' + userID + "/" + mailID,
        method: 'get'
    })
}