var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container"},[_c('el-row',[_c('el-col',{attrs:{"span":8}},[_c('el-card',[_c('div',{staticClass:"clearfix"},[_c('p',[_c('b',[_vm._v("Firebase id:")])]),_c('p',[_vm._v(_vm._s(_vm.userId))]),(_vm.haveData==true)?[_c('p',[_c('b',[_vm._v("Device Model:")])]),_c('p',[_vm._v(_vm._s(_vm.device_model))]),_c('p',[_c('b',[_vm._v("Operating System:")])]),_c('p',[_vm._v(_vm._s(_vm.operating_system))]),_c('p',[_c('b',[_vm._v("GPU:")])]),_c('p',[_vm._v(_vm._s(_vm.graphics_device_name))]),_c('p',[_c('b',[_vm._v("Device Name:")])]),_c('p',[_vm._v(_vm._s(_vm.device_name))]),_c('p',[_c('b',[_vm._v("Environment:")])]),_c('p',[_vm._v(_vm._s(_vm.environment))]),_c('p',[_c('b',[_vm._v("Join:")])]),_c('p',[_vm._v(_vm._s(_vm.time_created))]),_c('p',[_c('b',[_vm._v("Google ID:")])]),_c('p',[_vm._v(_vm._s(_vm.google_id))]),_c('p',[_c('b',[_vm._v("Apple ID:")])]),_c('p',[_vm._v(_vm._s(_vm.apple_id))])]:_vm._e()],2)])],1),_c('el-col',{staticClass:"box-card",attrs:{"span":14}},[_c('el-card',[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('h3',[_vm._v("Inbox")]),_c('el-button',{attrs:{"icon":"el-icon-message","type":"primary"},on:{"click":_vm.sendMail}},[_vm._v("Send mail")])],1),_c('div',[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingInbox),expression:"loadingInbox"}],attrs:{"data":_vm.inboxData}},[_c('el-table-column',{attrs:{"label":"Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.title))]),_c('div',{staticStyle:{"color":"#999"}},[_vm._v(_vm._s(row.content))])]}}])}),_c('el-table-column',{attrs:{"prop":"from","label":"From","width":"300px"}}),_c('el-table-column',{attrs:{"label":"Rewards","type":"expand","width":"100px"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('p',[_vm._v(_vm._s(props.row.rewards))])]}}])}),_c('el-table-column',{attrs:{"label":"Status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.status==='Claimed')?_c('el-tag',{attrs:{"type":"success"}},[_vm._v(_vm._s(row.status))]):_c('el-tag',{attrs:{"type":"info"}},[_vm._v(_vm._s(row.status))])]}}])}),_c('el-table-column',{attrs:{"label":"Time","width":"200px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('time',[_vm._v(_vm._s(_vm.getDateString(row.time)))])]}}])})],1)],1)]),_c('el-card',{staticStyle:{"margin-top":"10px"}},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('h3',[_vm._v("Transaction")]),_c('el-descriptions',{attrs:{"title":"","column":1}},[_c('el-descriptions-item',{attrs:{"label":"Total Packages","label-class-name":"label-bold"}},[_vm._v(_vm._s(_vm.totalTransactionData.totalPackage))]),_c('el-descriptions-item',{attrs:{"label":"Total Revenue","label-class-name":"label-bold"}},[_vm._v("$"+_vm._s(_vm.totalTransactionData.totalRevenue))])],1)],1),_c('div',[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingTransaction),expression:"loadingTransaction"}],attrs:{"data":_vm.transactionData}},[_c('el-table-column',{attrs:{"prop":"iap_package","label":"Package"}}),_c('el-table-column',{attrs:{"prop":"price","label":"Price"}}),_c('el-table-column',{attrs:{"label":"Status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.status==='Verified')?_c('el-tag',{attrs:{"type":"success"}},[_vm._v(_vm._s(row.status))]):_c('el-tag',{attrs:{"type":"info"}},[_vm._v(_vm._s(row.status))])]}}])}),_c('el-table-column',{attrs:{"label":"Time","width":"200px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('time',[_vm._v(_vm._s(_vm.getDateString(row.time)))])]}}])})],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }