<template>
  <div class="app-container">
    <el-row>
      <el-col :span="8">
        <el-card>
          <div class="clearfix">
            <p>
              <b>Firebase id:</b>
            </p>
            <p>{{ userId }}</p>
            <template v-if="haveData==true">
              <p>
                <b>Device Model:</b>
              </p>
              <p>{{device_model}}</p>
              <p>
                <b>Operating System:</b>
              </p>
              <p>{{operating_system }}</p>
              <p>
                <b>GPU:</b>
              </p>
              <p>{{ graphics_device_name }}</p>
              <p>
                <b>Device Name:</b>
              </p>
              <p>{{ device_name }}</p>
              <p>
                <b>Environment:</b>
              </p>
              <p>{{ environment }}</p>
              <p>
                <b>Join:</b>
              </p>
              <p>{{ time_created }}</p>

              <p>
                <b>Google ID:</b>
              </p>
              <p>{{ google_id }}</p>

              <p>
                <b>Apple ID:</b>
              </p>
              <p>{{ apple_id }}</p>
            </template>
          </div>
        </el-card>
      </el-col>
      <el-col :span="14" class="box-card">
        <el-card>
          <div slot="header" class="clearfix">
            <h3>Inbox</h3>
            <el-button icon="el-icon-message" type="primary" @click="sendMail">Send mail</el-button>
          </div>
          <div>
            <el-table :data="inboxData" v-loading="loadingInbox">
              <el-table-column label="Title">
                <template slot-scope="{row}">
                  <span>{{ row.title }}</span>
                  <div style="color: #999">{{ row.content }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="from" label="From" width="300px"></el-table-column>
              <el-table-column label="Rewards" type="expand" width="100px">
                <template slot-scope="props">
                  <p>{{ props.row.rewards }}</p>
                </template>
              </el-table-column>
              <el-table-column label="Status">
                <template slot-scope="{row}">
                  <el-tag v-if="row.status==='Claimed'" type="success">{{ row.status }}</el-tag>
                  <el-tag v-else type="info">{{ row.status }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="Time" width="200px">
                <template slot-scope="{row}">
                  <time>{{getDateString(row.time)}}</time>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-card>
        <el-card style="margin-top:10px">
          <div slot="header" class="clearfix">
            <h3>Transaction</h3>
            <el-descriptions title="" :column="1">
              <el-descriptions-item
                label="Total Packages"
                label-class-name="label-bold"
                >{{ totalTransactionData.totalPackage }}</el-descriptions-item
              >
              <el-descriptions-item
                label="Total Revenue"
                label-class-name="label-bold"
                >${{ totalTransactionData.totalRevenue }}</el-descriptions-item
              >
            </el-descriptions>
          </div>
          <div>
            <el-table :data="transactionData" v-loading="loadingTransaction">
              <el-table-column prop="iap_package" label="Package"></el-table-column>
              <el-table-column prop="price" label="Price"></el-table-column>
              <el-table-column label="Status">
                <template slot-scope="{row}">
                  <el-tag v-if="row.status==='Verified'" type="success">{{ row.status }}</el-tag>
                  <el-tag v-else type="info">{{ row.status }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="Time" width="200px">
                <template slot-scope="{row}">
                  <time>{{getDateString(row.time)}}</time>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<style>
.label-bold {
  font-weight: bold;
  color: cornflowerblue;
}
</style>

<script>
import {
  getUserByProjectID,
  getUserByID,
  getTransaction,
} from "@/api/users.js";
import { getInbox } from "@/api/inbox.js";
import convertDateString from "@/utils/get-date-string";
import router from "@/router";
import errorLog from "../../store/modules/errorLog";

export default {
  name: "User-Infomation",
  components: {},
  data() {
    return {
      userId: this.$route.params.user_id,
      haveData: false,
      device_model: "",
      operating_system: "",
      graphics_device_name: "",
      device_name: "",
      environment: "",
      db_id: "",
      time_created: "",
      google_id: "",
      apple_id: "",
      loadingInbox: true,
      loadingTransaction: true,
      inboxData: [],
      transactionData: [],
      totalTransactionData: { totalPackage: 0, totalRevenue: 0 },
    };
  },
  created() {
    getUserByID(this.userId)
      .then((response) => {
        let t = new Date(1970, 0, 1);
        t.setSeconds(response.time_created._seconds);
        this.device_model = response.device_model;
        this.operating_system = response.operating_system;
        this.graphics_device_name = response.graphics_device_name;
        this.device_name = response.device_name;
        this.environment = response.environment;
        this.db_id = response.db_id;
        this.time_created = this.getDateString(t);
        this.haveData = true;

        if (response.google_id !== undefined) {
          this.google_id = response.google_id;
        }

        if (response.apple_id !== undefined) {
          this.apple_id = response.apple_id;
        }
      })
      .catch((error) => {});

    getInbox(this.userId, 0, 10)
      .then((response) => {
        response.forEach((element) => {
          let t = new Date(1970, 0, 1);
          t.setSeconds(element.timestamp._seconds);
          this.inboxData.push({
            title: element.title,
            content: element.content,
            from: element.from,
            status: element.status,
            time: t,
            rewards: JSON.stringify(element.rewards),
          });
        });
        this.loadingInbox = false;
      })
      .catch((error) => {
        this.loadingInbox = false;
      });

    var countPackage = 0;
    var countRevenue = 0;
    getTransaction(this.userId)
      .then((response) => {
        response.forEach((element) => {
          let t = new Date(1970, 0, 1);
          countPackage++;
          let amount = parseFloat(element.amount);
          countRevenue += amount;
          t.setSeconds(element.timestamp._seconds);
          this.transactionData.push({
            iap_package: element.product,
            price: "$" + element.amount,
            status: "Verified",
            time: t,
          });
        });

        this.totalTransactionData = {
          totalPackage: countPackage,
          totalRevenue: parseInt(countRevenue),
        };

        this.loadingTransaction = false;
      })
      .catch((error) => {
        this.loadingTransaction = false;
      });
  },
  methods: {
    getDateString(d) {
      return convertDateString(d);
    },
    sendMail(id) {
      router.push({ name: "Send mail", params: { user_id: this.userId } });
    },
  },
};
</script>

<style>
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.box-card {
  margin-left: 10px;
}
</style>